<template>
  <v-container fluid class="fill-height">
    <v-row class="ma-0 d-flex flex-column justify-center align-center">
      <h1 class="mt-6 font-weight-light mb-5">
        {{ $ml.get("change_password") }}
      </h1>
      <p class="font-weight-light mb-5">
        {{ $ml.get("reset_description") }}
      </p>
      <v-card
        width="450"
        color="old-password-reset"
        class="rounded-sm pa-10 mt-6"
        outlined
      >
        <v-form v-model="validation" style="width: 100%">
          <v-row
            class="ma-0 d-flex flex-column"
            v-for="item in resetForm"
            :key="item.key"
          >
            <label :for="item.key" class="mb-1"
              >{{ $ml.get(item.label) }}:</label
            >
            <v-text-field
              :id="item.key"
              v-model="resetPayload[item.key]"
              :type="item.type"
              :disabled="item.disabled || loading"
              :readonly="item.readonly"
              outlined
              :rules="item.rules ? item.rules : []"
              :background-color="
                item.disabled
                  ? '#ECF0F1'
                  : resetPayload[item.key]
                  ? '#E8F0FE'
                  : 'white'
              "
              max-height="25"
              :error="!!item.error"
              :hide-details="!!item.hideDetails"
            />
            <v-input
              v-if="item.key === 'confirm_new_password'"
              :value="
                resetPayload.new_password == resetPayload.confirm_new_password
              "
              :rules="[rules.equalPasswords]"
            />
          </v-row>
          <div v-if="errorCode" class="error--text mb-7">
            <div class="error--text mb-3 font-weight-medium text-body-1">
              {{ errorsCode[errorCode] }}
            </div>

            <li
              v-for="error in errors"
              :key="error"
              class="caption font-weight-medium"
            >
              {{ requiredLabels[error] }}
            </li>
          </div>
          <div class="caption font-weight-medium mb-4">
            <div class="my-1">
              <v-icon
                v-text="hasMinLength ? 'mdi-check-circle' : 'mdi-close-circle'"
                :color="hasMinLength ? 'success' : 'error'"
                size="22"
                class="mr-2"
                small
              />
              {{ $ml.get("min_len_label") }}
              {{ minimumSize }}
            </div>
            <div v-if="activeRules.includes('has_lower')" class="my-1">
              <v-icon
                v-text="hasLower ? 'mdi-check-circle' : 'mdi-close-circle'"
                :color="hasLower ? 'success' : 'error'"
                size="22"
                class="mr-2"
                small
              />
              {{ $ml.get("checkbox_lowercase_label") }}
            </div>

            <div v-if="activeRules.includes('has_upper')" class="my-1">
              <v-icon
                v-text="hasUpper ? 'mdi-check-circle' : 'mdi-close-circle'"
                :color="hasUpper ? 'success' : 'error'"
                size="22"
                class="mr-2"
                small
              />
              {{ $ml.get("checkbox_uppercase_label") }}
            </div>
            <div v-if="activeRules.includes('has_number')" class="my-1">
              <v-icon
                v-text="hasNumber ? 'mdi-check-circle' : 'mdi-close-circle'"
                :color="hasNumber ? 'success' : 'error'"
                size="22"
                class="mr-2"
                small
              />
              {{ $ml.get("checkbox_numbers_label") }}
            </div>
            <div v-if="activeRules.includes('has_symbol')" class="my-1">
              <v-icon
                v-text="hasSymbol ? 'mdi-check-circle' : 'mdi-close-circle'"
                :color="hasSymbol ? 'success' : 'error'"
                size="22"
                class="mr-2"
                small
              />
              {{ $ml.get("checkbox_symbols_label") }}
            </div>
          </div>
          <v-btn
            :loading="loading"
            :disabled="!validation"
            block
            color="blue"
            x-large
            elevation="0"
            class="text-none text-body-1 font-weight-bold white--text"
            @click="changePassword()"
          >
            {{ $ml.get("change_password") }}
          </v-btn>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import { rulesBuilder } from "@/helpers/utils";

export default {
  name: "ResetPassword",
  data() {
    return {
      validation: null,
      loading: false,
      resetPayload: {
        username: "",
        password: "",
        new_password: "",
        confirm_new_password: "",
      },
      errorCode: "",
      errorBrokenRules: [],
      rules: {
        equalPasswords: (value) =>
          value || this.$ml.get("passwords_do_not_match"),

        min_len: (v) => v.length >= this.minimumSize,
        has_upper: (v) => /[A-Z]/.test(v),
        has_lower: (v) => /[a-z]/.test(v),
        has_number: (v) => /\d/.test(v),
        //eslint-disable-next-line
        has_symbol: (v) => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(v),
      },
      activeRules: [],
      errorsCode: {
        REQUIRED_BASIC_AUTH: this.$ml.get("REQUIRED_BASIC_AUTH"),
        INCORRECT_CREDENTIALS: this.$ml.get("INCORRECT_CREDENTIALS"),
        INVALID_PASSWORD: this.$ml.get("INVALID_PASSWORD"),
        SAME_PASSWORD: this.$ml.get("SAME_PASSWORD"),
        USER_NOT_FOUND: this.$ml.get("USER_NOT_FOUND"),
        429: this.$ml.get("REQUEST_LIMIT_EXCEEDED"),
      },
      minChar: 5,
      emailReadOnly: false,
      oauth: undefined,
    };
  },
  created() {
    const rules = Object.assign({}, this.$route.query);
    const oauth =
      this.$route.query.oauth || localStorage.getItem("oauth") || "google";

    localStorage.setItem("oauth", oauth);

    this.oauth = oauth;

    const ruleKeys = Object.keys(rules).filter((key) => key != "email");
    if (this.$route.query.email) {
      this.resetPayload.username = this.$route.query.email;
      this.emailReadOnly = true;
    }
    this.$router.replace({ query: null });

    if (ruleKeys.length) {
      ruleKeys.forEach((rule) => {
        if (rule == "min_len") {
          this.minimumSize = rules.min_len;
          this.activeRules.push(rule);
        } else if (rules[rule] == "true") {
          this.activeRules.push(rule);
        }
      });
    }
  },
  computed: {
    utilsRules() {
      return rulesBuilder(this);
    },
    requiredLabels() {
      return {
        min_len: `${this.$ml.get("min_len")} ${this.minimumSize} ${this.$ml.get(
          "min_len_sufix"
        )}`,
        has_upper: this.$ml.get("has_upper"),
        has_lower: this.$ml.get("has_lower"),
        has_number: this.$ml.get("has_number"),
        has_symbol: this.$ml.get("has_symbol"),
      };
    },
    passwordRules() {
      let rules = [this.rules.min_len, this.utilsRules.required];

      this.activeRules.forEach((rule) => {
        rules.push(this.rules[rule]);
      });
      return rules;
    },
    errors() {
      return this.errorBrokenRules.filter((item) => item);
    },
    minimumSize: {
      get() {
        return this.minChar;
      },
      set(new_value) {
        this.minChar = new_value;
      },
    },
    hasMinLength() {
      return this.resetPayload.new_password.length >= this.minimumSize;
    },

    hasSymbol() {
      return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(
        this.resetPayload.new_password
      );
    },

    hasUpper() {
      return /[A-Z]/.test(this.resetPayload.new_password);
    },

    hasLower() {
      return /[a-z]/.test(this.resetPayload.new_password);
    },

    hasNumber() {
      return /\d/.test(this.resetPayload.new_password);
    },

    resetForm() {
      return [
        {
          type: "email",
          key: "username",
          label: "email",
          disabled: false,
          readonly: this.emailReadOnly,
          rules: [this.utilsRules.required, this.utilsRules.validateEmail],
        },
        {
          type: "password",
          key: "password",
          label: "current_password",
          disabled: false,
          rules: [this.utilsRules.required],
        },
        {
          type: "password",
          key: "new_password",
          label: "new_password",
          disabled: false,
          rules: this.passwordRules,
        },
        {
          type: "password",
          key: "confirm_new_password",
          label: "confirm_new_password",
          disabled: false,
          rules: [],
          error:
            this.resetPayload.new_password !=
            this.resetPayload.confirm_new_password,
          hideDetails: true,
        },
      ];
    },
    allRules() {
      return [
        {
          completed: this.hasUpper,
          text: "Pelo menos uma letra maiúsucla",
          show: this.errorBrokenRules.includes("has_upper"),
        },
        {
          completed: this.hasLower,
          text: "Pelo menos uma letra minúscula",
          show: this.errorBrokenRules.includes("has_lower"),
        },
        {
          completed: this.hasSymbol,
          text: "Pelo menos um caractere especial",
          show: this.errorBrokenRules.includes("has_symbol"),
        },
        {
          completed: this.hasNumber,
          text: "Pelo menos um número",
          show: this.errorBrokenRules.includes("has_number"),
        },
      ];
    },
  },
  methods: {
    ...mapActions(["setSnackbarDefault"]),

    async changePassword() {
      this.loading = true;
      const url = `/sso/change-password?oauth=${this.oauth}`;
      const payload = {
        password: this.resetPayload.new_password,
      };
      const auth = {
        username: this.resetPayload.username,
        password: this.resetPayload.password,
      };
      await this.$axios
        .post(url, payload, {
          auth,
        })
        .then(() => {
          this.setSnackbarDefault({ message: "success_change_password" });
          const oauth = this.oauth || localStorage.getItem("oauth");
          if (oauth == "microsoft") {
            window.location.href = "https://login.microsoftonline.com";
          } else {
            window.location.href = "https://accounts.google.com/login";
          }
        })
        .catch((error) => {
          if (error.response.status === 429) {
            this.errorCode = 429;
            this.errorBrokenRules = [];
          } else {
            this.errorCode = error.response.data.code;
            this.errorBrokenRules = error.response.data.broken_rules || [];
            this.activeRules = this.errorBrokenRules;
            let minLenAux = null;
            let minLen =
              this.errorBrokenRules.find((item) => /\w*\d{1,}\w*/.test(item)) ||
              false;
            if (minLen) {
              let index = this.errorBrokenRules.indexOf(minLen);
              if (index > -1) {
                delete this.errorBrokenRules[index];
              }
              minLenAux = minLen.split(":");
              this.errorBrokenRules.push("min_len");
              this.minimumSize = parseInt(minLenAux[1]);
            }
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped>
.old-password-reset {
  background: #f7f7f7 !important;
}
</style>
